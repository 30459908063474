import dayjs from 'dayjs';
import { apolloClient } from '@/services/apollo';
import { EnergyExchangeOrderFieldsFragment } from '@/types/graphql/fragments';
import {
  Order,
  OrderPaginationInfo,
  type EnergyExchangeOrderFieldsWithRollAndDecFragment,
} from '@/types/graphql';
import { GET_ORDERS, GET_ORDERS_WITH_ROLL_AND_DEC } from '@/api/energyExchange';
import { UserRole } from '@/types/user';
import { t } from '@/utils/composable/localeHelper';
import { NotificationType } from '@/types/notification';
import {
  setQuoteState,
  setQuoteLoadingState,
  selectedFilterOrderConfirmations,
  activeSortFieldOrderConfirmations,
  sortOrderOrderConfirmations,
  setLatestCreatedOrder,
} from '@/utils/composable/EnergyExchange';
import { useNotificationStore, useUserStore } from '@/store';
import { fieldTranslationMap } from '@/types/energy-exchange';

const { addNotification } = useNotificationStore();
const userStore = useUserStore();

export const ORDER_CONFIRMATION_STATUS_LIST = [
  'placed',
  'pending',
  'canceled',
  'expired',
  'limit canceled',
];
export const OPEN_ORDERS_STATUS_LIST = ['open', 'limit cancellation requested'];

type NotificationOrderPayload = {
  id: string;
  number: string;
  volume: number;
  executionType: string;
  expiresAt: string;
  type: string;
  term: number;
  commodity: {
    id: string;
    name: string;
    __typename: 'Commodity';
  };
  priceId: string;
  price: number;
  status: string;
  deliveryDate: string[];
  paymentDate: string[];
  createdAt: string[];
  updatedAt: string[];
  totalPrice?: number | null;
  roll?: number | null;
  dec?: number | null;
  __typename: 'Order';
};

type NotificationPayload = {
  type:
    | 'PRICE_REJECTED'
    | 'PRICE_ACCEPTED'
    | 'ORDER_REQUESTED_TO_CANCEL'
    | 'ORDER_CANCELLED'
    | 'ORDER_FILLED';
  payload: {
    account: {
      id: string;
      name: string;
      firstName: string;
      middleName?: string;
      lastName: string;
      __typename: 'Account';
    };
    order: NotificationOrderPayload;
    product: {
      id: string;
      name: string;
      abbreviation: string;
      __typename: 'Product';
    };
    clientCompany: {
      id: string;
      name: string;
      __typename: 'ClientCompany';
    };
  };
  notification_id: string;
};

const updateCacheForOrderStatus = (
  orderData: NotificationOrderPayload,
  clientCompany: NotificationPayload['payload']['clientCompany'],
  account: NotificationPayload['payload']['account'],
): Promise<void> =>
  new Promise((resolve) => {
    const { cache } = apolloClient;
    const updatedOrder: EnergyExchangeOrderFieldsWithRollAndDecFragment = {
      __typename: 'Order',
      createdAt: orderData.createdAt[0],
      deliveryDate: orderData.deliveryDate?.[0] || '',
      executionType: orderData.executionType,
      expiresAt: orderData.expiresAt || '',
      id: orderData.id,
      number: orderData.number,
      paymentDate: orderData.paymentDate?.[0] || '',
      priceId: orderData.priceId || '',
      price: orderData.price || 0,
      status: orderData.status || '',
      term: orderData.term || 0,
      totalAmount: orderData.totalPrice || null,
      type: orderData.type || '',
      updatedAt: orderData.updatedAt?.[0] || '',
      volume: orderData.volume || 0,
      isPaid: false,
      createdBy: {
        __typename: 'Account',
        firstName: account.firstName || '',
        lastName: account.lastName || '',
        middleName: account.middleName || '',
      },
      clientCompany: {
        __typename: 'ClientCompany',
        id: clientCompany.id || '',
        legalName: clientCompany.name || '',
      },
      ...(orderData.executionType === 'limit' &&
        !userStore.isClientRole && {
          roll: orderData.roll || 0,
          dec: orderData.dec || 0,
        }),
    };

    const updateOrderBook = () => {
      const existingOrderBook = cache.readQuery<{
        orders: { collection: Order[]; paginationInfo: OrderPaginationInfo };
      }>({
        query: GET_ORDERS_WITH_ROLL_AND_DEC,
        variables: {
          status_list: OPEN_ORDERS_STATUS_LIST,
          executionType: 'limit',
          type: updatedOrder.type,
          page: 1,
          order: [{ dec: updatedOrder.type === 'buy' ? 'DESC' : 'ASC' }],
        },
      });

      if (existingOrderBook?.orders?.collection) {
        const isOrderFound = existingOrderBook.orders.collection.find(
          (order) => order.id === updatedOrder.id,
        )?.id;

        const newCollection = isOrderFound
          ? existingOrderBook.orders.collection.map((order) =>
              order.id === updatedOrder.id ? updatedOrder : order,
            )
          : [updatedOrder, ...existingOrderBook.orders.collection];

        const sortedCollection = newCollection.sort((a, b) =>
          updatedOrder.type === 'buy' ? (b.dec ?? 0) - (a.dec ?? 0) : (a.dec ?? 0) - (b.dec ?? 0),
        );

        cache.writeQuery<{
          orders: {
            collection: EnergyExchangeOrderFieldsWithRollAndDecFragment[];
            paginationInfo: OrderPaginationInfo;
          };
        }>({
          query: GET_ORDERS_WITH_ROLL_AND_DEC,
          data: {
            ...existingOrderBook,
            orders: {
              ...existingOrderBook.orders,
              collection: sortedCollection,
              paginationInfo: existingOrderBook.orders.paginationInfo,
            },
          },
          variables: {
            status_list: OPEN_ORDERS_STATUS_LIST,
            executionType: 'limit',
            type: updatedOrder.type,
            page: 1,
            order: [{ dec: updatedOrder.type === 'buy' ? 'DESC' : 'ASC' }],
          },
        });
      }
    };

    const updateOpenOrders = () => {
      const existingOpenOrders = cache.readQuery<{
        orders: { collection: Order[]; paginationInfo: OrderPaginationInfo };
      }>({
        query: GET_ORDERS,
        variables: {
          page: 1,
          executionType: 'limit',
          status_list: OPEN_ORDERS_STATUS_LIST,
        },
      });

      if (existingOpenOrders?.orders?.collection) {
        const isOrderFound = existingOpenOrders.orders.collection.find(
          (order) => order.id === updatedOrder.id,
        )?.id;

        const updatedOrders = isOrderFound
          ? existingOpenOrders.orders.collection.map((order) =>
              order.id === updatedOrder.id ? updatedOrder : order,
            )
          : [updatedOrder, ...existingOpenOrders.orders.collection];

        cache.writeQuery<{
          orders: {
            collection: EnergyExchangeOrderFieldsFragment[];
            paginationInfo: OrderPaginationInfo;
          };
        }>({
          query: GET_ORDERS,
          data: {
            ...existingOpenOrders,
            orders: {
              ...existingOpenOrders.orders,
              collection: updatedOrders,
              paginationInfo: existingOpenOrders.orders.paginationInfo,
            },
          },
          variables: {
            page: 1,
            executionType: 'limit',
            status_list: OPEN_ORDERS_STATUS_LIST,
          },
        });
      }
    };

    const updateOrderConfirmations = () => {
      const queryVariables = {
        page: 1,
        ...(selectedFilterOrderConfirmations.value
          ? { status_list: [selectedFilterOrderConfirmations.value] }
          : { status_list: ORDER_CONFIRMATION_STATUS_LIST }),
        ...(activeSortFieldOrderConfirmations.value !== 'createdAt'
          ? {
              order: [
                {
                  [fieldTranslationMap[activeSortFieldOrderConfirmations.value]]:
                    sortOrderOrderConfirmations.value,
                },
              ],
            }
          : {}),
      };

      const existingOrderConfirmations = cache.readQuery<{
        orders: { collection: Order[]; paginationInfo: OrderPaginationInfo };
      }>({
        query: GET_ORDERS,
        variables: queryVariables,
      });

      if (existingOrderConfirmations?.orders?.collection) {
        const isOrderFound = existingOrderConfirmations.orders.collection.find(
          (order) => order.id === updatedOrder.id,
        )?.id;

        const updatedOrders = isOrderFound
          ? existingOrderConfirmations.orders.collection.map((order) =>
              order.id === updatedOrder.id ? updatedOrder : order,
            )
          : [updatedOrder, ...existingOrderConfirmations.orders.collection];

        cache.writeQuery<{
          orders: {
            collection: EnergyExchangeOrderFieldsFragment[];
            paginationInfo: OrderPaginationInfo;
          };
        }>({
          query: GET_ORDERS,
          data: {
            ...existingOrderConfirmations,
            orders: {
              ...existingOrderConfirmations.orders,
              collection: updatedOrders,
              paginationInfo: existingOrderConfirmations.orders.paginationInfo,
            },
          },
          variables: queryVariables,
        });
      }
    };

    if (updatedOrder.status === 'open' || updatedOrder.status === 'limit cancellation requested') {
      updateOrderBook();
      updateOpenOrders();
    } else if (updatedOrder.status === 'limit canceled' || updatedOrder.status === 'placed') {
      updateOrderConfirmations();
    }

    resolve();
  });

// Only send notifications to company representatives, it's a response to creating an order which only they can
export const handleOrderViaWebsocket = async (newMessage: NotificationPayload) => {
  if (newMessage.type === 'PRICE_REJECTED') {
    if (userStore.getUserRole === UserRole.ROLE_COMPANY_REPRESENTATIVE) {
      addNotification({
        message: t('pages.pushNotifications.priceRejected.popupMessage'),
        type: NotificationType.ERROR,
        showIcon: true,
      });

      setQuoteLoadingState(false);
    }

    await updateCacheForOrderStatus(
      newMessage.payload.order,
      newMessage.payload.clientCompany,
      newMessage.payload.account,
    );

    setLatestCreatedOrder({
      orderNumber: newMessage.payload.order.number,
      status: newMessage.payload.order.status,
    });
  }

  if (newMessage.type === 'PRICE_ACCEPTED') {
    if (userStore.isClientRole) {
      addNotification({
        message:
          newMessage.payload.order.executionType === 'limit'
            ? t('pages.pushNotifications.priceAcceptedLimitOrder.popupMessage')
            : t('pages.pushNotifications.priceAcceptedMarketOrder.popupMessage'),
        type: NotificationType.SUCCESS,
        showIcon: true,
      });

      setQuoteLoadingState(false);
      setQuoteState('prices');
    }

    await updateCacheForOrderStatus(
      newMessage.payload.order,
      newMessage.payload.clientCompany,
      newMessage.payload.account,
    );

    setLatestCreatedOrder({
      orderNumber: newMessage.payload.order.number,
      status: newMessage.payload.order.status,
    });
  }

  if (['ORDER_REQUESTED_TO_CANCEL', 'ORDER_CANCELED', 'ORDER_FILLED'].includes(newMessage.type)) {
    await updateCacheForOrderStatus(
      newMessage.payload.order,
      newMessage.payload.clientCompany,
      newMessage.payload.account,
    );
  }
};

export async function getExchangeStatus() {
  try {
    const response = await fetch(`${import.meta.env.VITE_PORTAL_GRAPHQL_URL}/exchange/status`, {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${userStore.accessToken}`,
        'Content-Type': 'application/json',
      },
    });
    const data = await response.json();
    return data.status;
  } catch (error) {
    return 'error';
  }
}

// Uses recursive setTimeout to maintain precise market state transitions without drift over time.
// Recalculates exact milliseconds until next state change (open: 09:00, close: 17:30) on each cycle.
const isOutsideMarketHours = (): boolean => {
  const now = dayjs().tz('Europe/Amsterdam');
  const hours = now.hour();
  const minutes = now.minute();
  return hours < 9 || (hours === 17 && minutes >= 30) || hours > 17;
};

const calculateTimeDifference = (targetHour: number, targetMinute: number) => {
  const now = dayjs().tz('Europe/Amsterdam');
  let targetTime = now.hour(targetHour).minute(targetMinute).second(0).millisecond(0);

  if (now.isAfter(targetTime)) {
    targetTime = targetTime.add(1, 'day');
  }

  return targetTime.diff(now);
};

const determineMarketState = async () => {
  if (isOutsideMarketHours()) {
    setQuoteState('outsideHours');
    return calculateTimeDifference(9, 0);
  }

  const exchangeStatus = await getExchangeStatus();
  setQuoteState(exchangeStatus === 'enabled' ? 'prices' : 'disabled');

  return calculateTimeDifference(17, 30);
};

export const initializeMarketStatus = () => {
  const setMarketStatusTimeout = async () => {
    const timeDifference = await determineMarketState();
    window.setTimeout(setMarketStatusTimeout, timeDifference);
  };

  setMarketStatusTimeout();
};
